import { useTranslation } from "react-i18next";
import { Img, Paragraph } from "components";
import { Text } from "components";
import { Link } from "react-router-dom";

const HomeCreateProfile = () => {
  const { t, i18n } = useTranslation();

  return (
    <div id="trading-section" 
      className="container  h-screen flex flex-col justify-center bg-neutral-50 pb-[5rem]">
      <div className="flex flex-row sm:flex-col">
        <div className="flex flex-col w-1/2 sm:w-full p-[5rem]">
          <div className="flex flex-row">
            <Text className="text-blue-600 pb-[2rem]">Create Profile</Text>
          </div>
          <div className="flex flex-row">
            <Text className="text-[2rem] font-semibold">
              Easy Way to Get Started
            </Text>
          </div>
          <div className="flex flex-row">
            <Paragraph className="text-base text-wrap text-black-900">
              Start your crypto journey with confidence. Whether you're a
              beginner or an experienced trader, our platform provides the
              tools, resources, and support you need to invest smartly and
              securely in the world of digital assets.
            </Paragraph>
          </div>
          <Link to={`/${i18n.language}/sign-in`}>
            <div className="flex flex-row pt-[2rem] cursor-pointer">
              <text className="text-blue-600 text-sm underline">
                Start Trading
              </text>
              <div className="pl-2">
                <Img className="w-[20px]" src="/images/home/right-arrow.svg" />
              </div>
            </div>
          </Link>
        </div>
        <div className="flex flex-col w-1/2 sm:w-full p-[2rem] pt-[5rem]">
          <div className="flex flex-row gap-[3rem]">
            <div className="flex flex-col bg-blue-scfx p-[1rem] w-[16rem]  items-center">
              <div className="flex flex-row justify-center">
                <Img src="/images/home/account.svg" alt="account" />
              </div>
              <div className="flex flex-row justify-center text-center py-2">
                <Text className="font-semibold">
                  Create an Account
                </Text>
              </div>
              <div className="flex flex-row justify-center items-center text-center w-[80%]">
                <Text className="text-xs">
                  Sign Up with your email and mobile in just 5 minutes
                </Text>
              </div>
            </div>
            <div className="flex flex-col bg-blue-scfx p-[1rem] w-[16rem] items-center">
              <div className="flex flex-row justify-center">
                <Img
                  src="/images/home/verifiedBankAcc.svg"
                  alt="Verified Bank Account"
                />
              </div>
              <div className="flex flex-row justify-center text-center py-2">
                <Text className="font-semibold">
                  Verified Bank Account
                </Text>
              </div>
              <div className="flex flex-row justify-center items-center text-center w-[80%]">
                <Text className="text-xs">
                  Verify Your Bank Account in Easy Way
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-[3rem] pt-[3rem] ml-[2rem]">
            <div className="flex flex-col bg-blue-scfx p-[1rem] items-center w-[15rem] ">
              <div className="flex flex-row justify-center  ">
                <Img src="/images/home/wallet.svg" alt="wallet" />
              </div>
              <div className="flex flex-row justify-center text-center py-2">
                <Text className="font-semibold">
                  Add Funds to Wallet
                </Text>
              </div>
              <div className="flex flex-row justify-center items-center text-center w-[80%]">
                <Text className="text-xs">
                  Quickly add money to your investment wallet{" "}
                </Text>
              </div>
            </div>
            <div className="flex flex-col bg-blue-scfx items-center p-[1rem] w-[15rem]">
              <div className="flex flex-row justify-center">
                <Img
                  src="/images/home/tradingGraph.svg"
                  alt="Trading Instantly"
                />
              </div>
              <div className="flex flex-row justify-center text-center py-2">
                <Text className="font-semibold">
                  Start Trading Instantly
                </Text>
              </div>
              <div className="flex flex-row justify-center items-center text-center w-[80%]">
                <Text className="text-xs ">
                  Buy & Sell a variety of top coins at the best prices
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCreateProfile;
