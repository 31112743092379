import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";

export default function InvestmentMenu({ selectedItem = "", setSelectedCoin, setIsCrypto = ()=>{}, setBacktoProduct =()=>{} }) {

  return (
    <div>
      <Sidebar className="rounded-lg">
        <Menu iconShape="square" >
          <SubMenu title="Components" label="Crypto" >
              <MenuItem
                className={`${
                  selectedItem === "btc" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('btc'); setIsCrypto(true); setBacktoProduct('btc')}}
              >
                Bitcoin
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "eth" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('eth') ; setIsCrypto(true) ; setBacktoProduct('eth')}}
              >
                Ethereum
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "dot" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('dot') ; setIsCrypto(true); setBacktoProduct('dot')}}
              >
                Polkadot
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "ht"
                    ? "bg-gray-400 text-red-600"
                    : ""
                } `}
                onClick={() => {setSelectedCoin('ht') ; setIsCrypto(true); setBacktoProduct('ht') }}
              >
                Houbitoken
              </MenuItem>
              {" "}
              <MenuItem
                className={`${
                  selectedItem === "xrp" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('xrp') ; setIsCrypto(true); setBacktoProduct('xrp')}}
              >
                Ripple
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "doge" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('doge') ; setIsCrypto(true) ; setBacktoProduct('doge') }}
              >
                Dogecoin
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "bnb" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('bnb') ; setIsCrypto(true); setBacktoProduct('bnb')}}
              >
                Binancecoin
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "trx" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('trx') ; setIsCrypto(true); setBacktoProduct('trx') }}
              >
                Tron
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "sol" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('sol') ; setIsCrypto(true); setBacktoProduct('sol') }}
              >
                Solana
              </MenuItem>
              {" "}
              <MenuItem
                className={`${
                  selectedItem === "usdt" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('usdt') ; setIsCrypto(true); setBacktoProduct('usdt')}}
              >
                USDT
              </MenuItem>
          </SubMenu>
          <SubMenu title="Share" label="Share">
              <MenuItem
                className={`${
                  selectedItem === "SP500" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('SP500') ; setBacktoProduct('SP500')}}
              >
                SP 500
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "DXY" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('DXY'); setBacktoProduct('DXY')}}
              >
                US Dollar Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "VIX" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('VIX'); setBacktoProduct('VIX')}}
              >
                Volatility Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "DAX" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('DAX'); setBacktoProduct('DAX')}}
              >
                DAX Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "HSI" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('HSI'); setBacktoProduct('HSI')}}
              >
                Hang Sang Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "CAC40" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('CAC40'); setBacktoProduct('CAC40')}}
              >
                CAC40 Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "NIKKEI" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('NIKKEI'); setBacktoProduct('NIKKEI')}}
              >
                Nikkei Index
              </MenuItem>
              <MenuItem
                className={`${
                  selectedItem === "NDX" ? "bg-gray-400 text-red-600" : ""
                } `}
                onClick={() => {setSelectedCoin('NDX'); setBacktoProduct('NDX')}}
              >
                Nasdaq Index
              </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
}
