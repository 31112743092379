import React from "react";
import Header from "../layout/Header";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import Footer from "components/Footer";
import { shallow } from "zustand/shallow";

export default function DashboardLayout({userInfo, children}){
  // const userInfo = usePersonalInformation(
  //   (state) => ({ user: state.user, reset: state.reset }),
  //   shallow
  // );
  return (
    <div>
      <Header userInfo={userInfo}/>
        {children}
      <Footer />
    </div>
    
  )
}