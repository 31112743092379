import DashboardLayout from "components/dashboard/DashboardLayout";
import React, { useState, useEffect, useRef } from "react";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import useAuth from "../../../hooks/zustand/useAuth";
import { useTranslation } from "react-i18next";
import useLocalStorage from "hooks/useLocalStorage";
import { shallow } from "zustand/shallow";
import Card from "components/dashboard/card/Card";
import CardHeader from "components/dashboard/card/Card.header";
import { Link } from "react-router-dom";
import TodayTrade from "components/dashboard/card/Card.today-trade";
import TabNavigationSmall from "components/dashboard/tabs/Tabs-navigation-small";
import TradingViewWidget from "../Coin/TradingView";
import MarketWatchList from "./MarketWatchList";

export default function Dashboard() {
  const userInfo = usePersonalInformation(
    (state) => ({
      user: state.user,
      reset: state.reset,
      todayTrade: state.todayTrade,
    }),
    shallow
  );
  const usePersonal = usePersonalInformation();
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [attempt, setAttempt] = useLocalStorage("withdrawalAttempt", 0);
  const [status, setStatus] = useState(userInfo?.user.status || "online");
  const { t, i18n } = useTranslation();
  const TabList = ["Crypto", "Share Index"];
  const [tabSelected, setTabSelected] = useState({
    currentTab: 0,
    noTab: TabList.length,
  });

  const maxSuspendAttempts =
    userInfo?.user?.maxSuspendAttempts ??
    process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;

  useEffect(() => {
    const controller = new AbortController();
    if (userInfo.user.firstName === "") {
      usePersonal.getData();
    }
    async function initialize() {
      await usePersonal.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await usePersonal.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user._id]);

  useEffect(() => {
    if (
      userInfo?.user?.status === "online" ||
      userInfo?.user?.status === "active"
    ) {
      //setAttempt(0);
      const attemptPayload = {
        suspendedAttempts: 0,
        userStatus: "online",
      };
      const res = usePersonal.updateUserSuspendedAttempts(attemptPayload);
    }

    if (
      userInfo?.user.status === "suspend" &&
      userInfo?.user.suspendedAttempts >= maxSuspendAttempts
    ) {
      setStatus("suspend");
    } else {
      setStatus(
        userInfo?.user.status !== "suspend" ? userInfo?.user.status : "online"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user.status]);

  const BTCReff = useRef(null);
  const ETHReff = useRef(null);
  const DOTReff = useRef(null);
  const HTReff = useRef(null);
  const XRPReff = useRef(null);
  const DOGEReff = useRef(null);
  const BNBReff = useRef(null);
  const TRXReff = useRef(null);
  const SOLReff = useRef(null);
  const USDTReff = useRef(null);

  const SPXReff = useRef(null);
  const NDQReff = useRef(null);
  const DJIReff = useRef(null);
  const VIXReff = useRef(null);
  const DXYReff = useRef(null);
  const DAXReff = useRef(null);
  const HSIReff = useRef(null);
  const CAC40Reff = useRef(null);
  const NIKKEIReff = useRef(null);
  const NASDAQReff = useRef(null);

  const EXYReff = useRef(null);
  const DXYForexReff = useRef(null);
  const BXYReff = useRef(null);
  const SXYReff = useRef(null);
  const JXYReff = useRef(null);
  const CXYReff = useRef(null);
  const AXYReff = useRef(null);
  const ZXYReff = useRef(null);

  const cryptoReff = useRef(null);
  const watchListReff = useRef(null);
  return (
    <DashboardLayout userInfo={userInfo}>
      <div className="container bg-background_gray flex flex-col items-center">
        <div className="flex flex-row sm:flex-col w-[90%] sm:w-full">
          <div className="flex flex-col w-1/2 sm:w-full p-10">
            <Card className="!h-75vh sm:!h-[50vh] bg-white-fff">
              {/* TOP CARD */}
              <CardHeader leftTitle={t("member.dashboard.Today Trade")} />
              <div className="h-5/6  overflow-y-auto">
                {status !== "missinginfo"
                  ? userInfo?.todayTrade?.length > 0 &&
                    userInfo?.todayTrade?.map((a, b) => {
                      return (
                        <Link key={b} to={`/dashboard/profile-detail/${a._id}`}>
                          <TodayTrade
                            member={userInfo?.user?.phoneNumber}
                            logo={a.product.coinId}
                            profile={`${a.product.coinId} ${a.profileDesc}`}
                            // profitLoss={a.profitLossAmount + a.investAmount}
                            investAmount={a.investAmount}
                            profitLoss={a.profitLossAmount}
                            changingTime={a.profitChangingTime}
                            percentage={(
                              (a.profitLossAmount / a.investAmount) *
                              100
                            ).toFixed(1)}
                            isProfit={a.isProfit}
                            isPlay={a.isPlay}
                            playDate={a.playDate}
                            finishPlayDate={a.finishPlayDate}
                          />
                        </Link>
                      );
                    })
                  : null}
              </div>
            </Card>
          </div>
          <div className="flex flex-col w-1/2 sm:w-full p-10">
            <Card className="!h-75vh bg-white-fff">
              {/* TOP CARD */}
              <CardHeader
                leftTitle={t("member.dashboard.Cryptocurrency Performance")}
              />
              {/* MIDDLE CARD */}
              <section
                className="max-w-full h-80vh"
                aria-multiselectable={false}
              >
                <div>
                  <ul className="flex items-center w-full" role="tablist">
                    {TabList.map((value, index) => {
                      return (
                        <TabNavigationSmall
                          key={index}
                          tabSelected={tabSelected}
                          setTabSelected={setTabSelected}
                          tabNum={index}
                          label={value}
                        />
                      );
                    })}
                  </ul>
                </div>
                <div className=" grid h-[75%] overflow-y-auto ">
                  {tabSelected.currentTab === 0 && (
                    <div className="w-full">
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/btc"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="COINBASE:BTCUSD"
                          containerRef={BTCReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/eth"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="COINBASE:ETHUSD"
                          containerRef={ETHReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/dot"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="COINBASE:DOTUSD"
                          containerRef={DOTReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/ht"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="CRYPTO:HTUSD"
                          containerRef={HTReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/xrp"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="COINBASE:XRPUSD"
                          containerRef={XRPReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/doge"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="BINANCE:DOGEUSD"
                          containerRef={DOGEReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/bnb"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="BINANCE:BNBUSD"
                          containerRef={BNBReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/trx"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="BINANCE:TRXUSD"
                          containerRef={TRXReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/sol"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="BINANCE:SOLUSD"
                          containerRef={SOLReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/usdt"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="GEMINI:USDTUSD"
                          containerRef={USDTReff}
                        />
                      </div>
                    </div>
                  )}
                  {tabSelected.currentTab === 1 && (
                    <div className="w-full">
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/SP500"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="FRED:SP500"
                          containerRef={SPXReff}
                        />
                      </div>
                      {/* <div className="w-full  relative">
                    <Link
                      to='/dashboard/us100'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget
                      symbol="TVC:NDQ"
                      containerRef={NDQReff}
                    />
                  </div> */}
                      {/* <div className="w-full  relative">
                    <Link
                      to='/dashboard/dowjones'
                      className='absolute w-full h-full z-infinity'
                    ></Link>
                    <TradingViewWidget 
                      symbol="DJ:DJI" 
                      containerRef={DJIReff} 
                      />
                  </div> */}
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/DXY"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="CAPITALCOM:DXY"
                          containerRef={DXYReff}
                        />
                      </div>
                      <div className="w-full  relative">
                        <Link
                          to="/dashboard/investment-product/VIX"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="CAPITALCOM:VIX"
                          containerRef={VIXReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/DAX"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="XETR:DAX"
                          containerRef={DAXReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/HSI"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="HSI:HSI"
                          containerRef={HSIReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/CAC40"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="INDEX:CAC40"
                          containerRef={CAC40Reff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/NIKKEI"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="FRED:NIKKEI225"
                          containerRef={NIKKEIReff}
                        />
                      </div>
                      <div className="w-full relative">
                        <Link
                          to="/dashboard/investment-product/NDX"
                          className="absolute w-full h-full z-infinity"
                        ></Link>
                        <TradingViewWidget
                          symbol="NASDAQ:NDX"
                          containerRef={NASDAQReff}
                        />
                      </div>
                    </div>
                  )}
                  {/* {tabSelected.currentTab === 2 && (
                 <div className="w-full">
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/dxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="CAPITALCOM:DXY"
                     containerRef={DXYForexReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/exy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:EXY"
                     containerRef={EXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/bxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:BXY"
                     containerRef={BXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/sxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:SXY"
                     containerRef={SXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/jxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:JXY"
                     containerRef={JXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/cxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:CXY"
                     containerRef={CXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/axy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:AXY"
                     containerRef={AXYReff}
                   />
                 </div>
                 <div className="w-full  relative">
                   <Link
                     to='/dashboard/forex/zxy'
                     className='absolute w-full h-full z-infinity'
                   ></Link>
                   <TradingViewWidget
                     symbol="TVC:ZXY"
                     containerRef={ZXYReff}
                   />
                 </div>
               </div>
              )} */}
                </div>
              </section>
            </Card>
          </div>
        </div>
        <div className="flex flex-row w-[90%] px-10 pb-10 ">
          <div className="flex flex-col bg-white-fff w-full">
            <MarketWatchList containerRef={watchListReff} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
