import Header from './Header';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import ChatWidget from './chat-widget';

const Layout = ({ changeColorWhileScroll, children }) => {
  const scrollToMarket = () => {
    const marketSection = document.getElementById('market-section');
    if (marketSection){
      marketSection.scrollIntoView({behavior: 'smooth'});
    }
  }
  const scrollToTrading = () => {
    const tradingSection = document.getElementById('trading-section');
    if (tradingSection){
      tradingSection.scrollIntoView({behavior: 'smooth'});
    }
  }
  const scrollToService = () => {
    const serviceSection = document.getElementById('service-section');
    if (serviceSection){
      serviceSection.scrollIntoView({behavior: 'smooth'});
    }
  }
  const scrollToTestimonial = () => {
    const testimonialSection = document.getElementById('testimonial-section');
    if (testimonialSection){
      testimonialSection.scrollIntoView({behavior: 'smooth'});
    }
  }
  const scrollToAward = () => {
    const awardSection = document.getElementById('award-section');
    if (awardSection){
      awardSection.scrollIntoView({behavior:"smooth"});
    }
  }
 
  return (
    <>
      <ScrollToTop />
      <Header 
        changeColorWhileScroll={changeColorWhileScroll} 
        scrollToMarket={scrollToMarket}
        scrollToTrading={scrollToTrading}
        scrollToService={scrollToService} 
        scrollToTestimonial={scrollToTestimonial}
        scrollToAward={scrollToAward}
        />
      {children}
      {(process.env.REACT_APP_ISENABLED === true) && (<ChatWidget />)}
      <Footer />
    </>
  );
};

export default Layout;
