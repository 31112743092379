import { useEffect } from 'react';

export default function TradingViewWidget({
  symbol,
  containerRef,
  type = 'single-qoute',
}) {
  useEffect(() => {
    let isMounted = true;
    const script = document.createElement('script');
    if (type === 'single-qoute') {
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbol,
        width: '100%',
        colorTheme: 'dark',
        isTransparent: false,
        locale: 'ms_MY',
      });
    }
    if (type === 'mini-chart') {
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbol,
        width: '100%',
        colorTheme: 'light',
        isTransparent: false,
        locale: 'ms_MY',
        dateRange: '12M',
        autoSize: false,
      });
    }

    const currentContainerRef = containerRef.current;
    if (currentContainerRef && isMounted) {
      currentContainerRef.appendChild(script);
    }

    return () => {
      if (currentContainerRef && currentContainerRef.contains(script)) {
        currentContainerRef.removeChild(script);
      }
      isMounted = false;
    };
  }, [symbol, containerRef, type]);

  return (
    <div
      className={`tradingview-widget-container ${
        type === 'mini-chart' ? '!h-max' : '!h-24'
      } overflow-hidden`}
      ref={containerRef}
    >
    </div>
  );
}
