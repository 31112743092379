import React from "react";
import { Text, Img, Paragraph } from "components";

export default function HomeBestServiceDetail({
  componentClass = "",
  imgSrc, 
  title, 
  subTitle
}) {
  return (
    <div className={`${componentClass} flex flex-col w-full h-[14rem] bg-blue-scfx shadow-lg p-5 rounded-[2rem] `} >
      <Img className="w-[40px] py-2 " src={imgSrc} alt={title} />
      <Text className="font-semibold">
        {title}
      </Text>
      <Paragraph className="text-xs text-black-900">
       {subTitle}
      </Paragraph>
    </div>
  );
}
