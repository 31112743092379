import React from "react";
import { mockLanguages } from "../../mock/mockData";
import { Text } from "components";

export default function Multiligual({
  name = "languages",
  selectedValue = "",
  className = "",
}) {
  return (
    <div className={`${className}`}>
      <select
        name={name}
        value={selectedValue}
        className="rounded-lg sm:pt-1 sm:pb-1 text-[0.88rem]"
      >
        {mockLanguages.map((item) => {
          return (
            <option key={item} value={item}>
              {item.toUpperCase()}
            </option>
          );
        })}
      </select>
    </div>
  );
}
