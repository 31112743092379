import ReactApexChart from "react-apexcharts";
import Heading from "../../../../components/dashboard/typography/Heading";
import Paragraph from "../../../../components/dashboard/typography/Paragraph";
import Spacing from "../../../../components/dashboard/spacing/Spacing";
import Button from "../../../../components/dashboard/button/Button";
import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import useInvestmentListing from "../../../../hooks/zustand/member/useInvestmentListing";
import { BarLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import DashboardLayout from "components/dashboard/DashboardLayout";
import InvestmentMenu from "components/InvestmentMenu";
import { useNavigate } from "react-router-dom";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";

export default function ProfitLossHistoryProfile() {
  const params = useParams();
  const profile = useInvestmentListing();
  const userInfo = usePersonalInformation((state)=>({ user: state.user, state: state.state}));
  const investmentProfile = useInvestmentListing((state)=>({ detailProfile : state.detailProfile}));
  const [selectedCoin, setSelectedCoin] = useState();
  const [isCrypto, setIsCrypto] = useState(false);
  const { t } = useTranslation();
  const [filter, setFilter] = useState("this-week");
  const navigate = useNavigate();

  const handlerBackToMainProduct = (coin) => {
    navigate(`/dashboard/investment-product/${coin}`);
  }

  useEffect(() => {
    async function detail() {
      await profile.getDetailProfile(params.id, filter);
    }
    detail();

    console.log("profile", investmentProfile?.detailProfile?.investment?.coin);

    setSelectedCoin(profile.detailProfile?.investment?.coin);
    
    return () => {
      profile.resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, filter]);

  const xList = profile?.detailProfile?.label;
  const yList = profile?.detailProfile?.profit;

  const chartOptions = {
    chart: { toolbar: false },
    dataLabels: { enabled: false },
    // stroke: { curve: filter === "five-minutes" ? "straight" : "smooth", width: 2 },
    stroke: {
      curve: filter === "five-minutes" ? "smooth" : "smooth",
      width: 2,
    },
    markers: { size: 0, style: "hollow" },
    xaxis: {
      categories: xList,
      labels: {
        style: {
          colors: "#000000",
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#000000",
          fontWeight: 500,
        },
      },
    },
    colors: ["#4df5a4"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.05,
        stops: [50, 100, 100, 100],
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 400,
            width: "100%",
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 400,
            width: 800,
          },
        },
      },
    ],
  };

  // function replaceZerosWithRandom(array) {
  //   if (Array.isArray(array)) {
  //     const maxNumber = Math.max(...array);

  //     function generateRandomNumber(max) {
  //       return Math.floor(Math.random() * max) + 1;
  //     }

  //     return array.map((item) =>
  //       item === 0 ? generateRandomNumber(maxNumber) : item
  //     );
  //   }
  //   return array;
  // }

  const manipulatedData = useMemo(() => {
    // return filter === 'today' ? replaceZerosWithRandom(yList) : yList;
    return yList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yList]);

  const chartSeries = [
    {
      name: t("member.tradingview.Profit/Loss"),
      data: manipulatedData,
    },
  ];

  return (
    <DashboardLayout userInfo={userInfo}>
      <div className="p-10 bg-background_gray flex justify-center">
        <div className="flex flex-col w-[20%]">
        <InvestmentMenu selectedItem={selectedCoin} setSelectedCoin={setSelectedCoin} setIsCrypto={setIsCrypto} setBacktoProduct={handlerBackToMainProduct} /> 
        </div>
        <div className="flex flex-col w-[90%] bg-white-fff rounded-lg">
          <Heading color="black" className="font-semibold" size="h2">
            {t("member.tradingview.Profit/Loss History")}
          </Heading>
          <Paragraph size="large" color="black">{`${
            profile?.detailProfile?.investment?.coin ?? "Profile investment"
          } ${profile?.detailProfile?.investment?.profile ?? ""}`}</Paragraph>
          <Spacing />
          <Paragraph size="large" color="black" className="font-semibold">
            {t("member.tradingview.Range Times")}
          </Paragraph>
          <div className="flex max-w-xl space-x-4">
            <Button
              onClick={() => setFilter("five-minutes")}
              backgroundColor={
                filter === "five-minutes" ? "primary" : "data-table-action"
              }
              className={`text-white-a700_99 py-3 ${
                filter === "five-minutes"
                  ? "outline outline-2 outline-white font-semibold"
                  : "text-black-900"
              }`}
            >
              {t("member.tradingview.5 Minutes")}
            </Button>
            <Button
              onClick={() => setFilter("today")}
              backgroundColor={
                filter === "today" ? "primary" : "data-table-action"
              }
              className={`text-white-a700_99 py-3 ${
                filter === "today"
                  ? "outline outline-2 outline-white font-semibold"
                  : "text-black-900"
              }`}
            >
              {t("member.tradingview.Today")}
            </Button>
            <Button
              backgroundColor={
                filter === "this-week" ? "primary" : "data-table-action"
              }
              className={`text-white-a700_99 py-3 ${
                filter === "this-week"
                  ? "outline outline-2 outline-white font-semibold"
                  : "text-black-900"
              }`}
              onClick={() => setFilter("this-week")}
            >
              {t("member.tradingview.This Week")}
            </Button>
            <Button
              backgroundColor={
                filter === "this-month" ? "primary" : "data-table-action"
              }
              className={`text-white-a700_99 py-3 ${
                filter === "this-month"
                  ? "outline outline-2 outline-white font-semibold"
                  : "text-black-900"
              }`}
              onClick={() => setFilter("this-month")}
            >
              {t("member.tradingview.This Month")}
            </Button>
          </div>
          <Spacing />
          <div className="bg-transparent w-full overflow-x-scroll lg:overflow-hidden">
            {profile.loading ? (
              <div className="w-full h-96 flex items-center justify-center">
                <BarLoader color="#FFFFFF" height={5} />
              </div>
            ) : (
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="area"
                height={400}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
