import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/zustand/useAuth";
import useLogStore from "../../hooks/zustand/admin-log/useLogStore";
import { useNavigate, useLocation } from "react-router-dom";
import { handleLoginResponse } from "../../lib/api/handleApiResponse";
// components
import { FormProvider, RHFInput, RHFInputAddon } from "../../components/RHForm";
import ButtonLp from "../../components/ButtonLp";
import { enqueueSnackbar } from "notistack";
import ModalAccountLocked from "./Modal/Modal.account-locked";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useHomeSlider from "../../hooks/zustand/useHomeSlider";
import { Img, Heading, Text } from "components";

const schemaUsePhone = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .matches(/^\d+$/, "Number only!")
    .min(9, "Minimum 9 digit numbers")
    .max(13, "Maximum 13 digit numbers"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters"),
});

const schemaUseEmail = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(32, "Password must be at most 32 characters"),
});

const defaultValues = {
  phoneNumber: "",
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const [useEmail, setUseEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const attempt = useLocalStorage("withdrawalAttempt");
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);

  // const initialGetBgImg = async () => {
  //   const resp = await homeSliderStore.getBgImg();
  //   if (resp.status === 200) {
  //     if (resp?.data?.slider?.bg_img ?? "" !== "")
  //       setBgImg(resp.data.slider.bg_img);
  //   }
  // };

  useEffect(() => {
    if (location.state?.success === true) {
      enqueueSnackbar("Register successfully. You can login!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    navigate({ ...location, state: { success: false } }, { replace: true });

    const currLanguage =
      params.language ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
    i18next.changeLanguage(params.language);

    //initialGetBgImg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm({
    resolver: yupResolver(useEmail ? schemaUseEmail : schemaUsePhone),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const handleUseLogin = () => {
    setUseEmail(!useEmail);
    reset();
  };

  const onSubmit = async () => {
    const res = await auth.login(
      !useEmail ? values.phoneNumber : values.email,
      values.password,
      !useEmail ? "phone" : "email"
    );
    const validated = handleLoginResponse(res, setError);
    if (
      validated &&
      res?.user?.role !== "admin" &&
      res?.user?.status !== "lock"
    ) {
      const memberId = !useEmail ? values.phoneNumber : values.email;
      const maxSuspendAttempts =
        res?.user?.maxSuspendAttempts ??
        process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;
      if (
        res?.user?.status === "suspend" &&
        res?.user?.suspendedAttempts >= maxSuspendAttempts
      ) {
        await logStore.createData({
          adminId: "",
          memberId: memberId,
          log: `${memberId} login unsuccessful due to suspended`,
          payload: values,
          action: "login",
          module: "member",
          table: "users",
        });
        navigate(`/dashboard/withdraw`);
      } else {
        await logStore.createData({
          adminId: "",
          memberId: memberId,
          log: `${memberId} login successfully`,
          payload: values,
          action: "login",
          module: "member",
          table: "users",
        });
        navigate(`/dashboard`);
      }
    }
    if (validated && res.user?.role !== "" && res.user?.status === "lock") {
      setOpen(true);
      reset();
    }
    auth.setDefaultPassword("");
  };

  return (
    <>
      {/* <div
      className='w-full h-screen relative'
      style={{
        backgroundImage: `url('${bgImg}')`,
        opacity: 0.5,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
    </div>     */}
      <div className="flex flex-row  sm:flex-col-reverse ">
        <div className="w-1/2 flex flex-col md:flex-row md:w-full sm:flex-row sm:w-full">
          <div className="w-full flex">
            <div className=" bg-primary-primary h-screen md:h-[100%] w-full sm:p-5 ">
              <div className=" m-auto   bg-cover bg-no-repeat ">
                <div className="mt-[3.75rem] flex flex-col gap-[3.00rem]">
                  <Img
                    src="/images/img_illustration.svg"
                    alt="Main Illustration"
                    className="h-[22.13rem]  object-contain "
                  />
                  <div className="mx-[1.75rem] flex flex-col items-center gap-[1.50rem] md:mx-0">
                    <Heading
                      size="heading_h3"
                      as="h2"
                      className="ml-[1.13rem] text-center text-[1.75rem] font-extrabold leading-[2.13rem] text-black-900 md:ml-0 md:text-[1.63rem] sm:text-[1.50rem]"
                    >
                      <>
                        Buy & sell Each Digital
                        <br />
                        Cryptocurrency
                      </>
                    </Heading>
                    <Text
                      as="p"
                      className="text-center text-[0.88rem] font-normal leading-[1.31rem] text-black-900"
                    >
                      <>
                        Easily Buy bitcoin and other cryptocurrencies using
                        <br />
                        wide range of payment option. Discover exclusive
                        <br />
                        digital collection of us.
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-col  justify-center  sm:pb-10 sm:w-full">
          <div
            className="absolute top-5 right-5  
          sm:relative sm:pb-5 sm:w-full sm:flex sm:justify-end"
          >
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-24"
            />
          </div>

          <div className="w-[90%]  bg-white overflow-hidden  p-5 lg:px-10  mx-auto">
            <div className="pb-10">
              <Text className="capitalize text-[1.5rem] font-semibold">
                Login to {process.env.REACT_APP_TITLE.toLowerCase()}
              </Text>
            </div>
            {auth.defaultPassword !== "" ? (
              <div className="w-full bg-c-green/50 p-3 mb-5 rounded-lg">
                <p className="text-sm font-semibold">
                  Here's your new password :{" "}
                  {auth.defaultPassword ? auth.defaultPassword : null}
                </p>
              </div>
            ) : null}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
                {!useEmail ? (
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-sm font-medium text-gray-900">
                        {t("home.signin.phone")}
                      </label>
                      <button
                        className="text-sm font-medium text-blue-500"
                        type="button"
                        onClick={() => handleUseLogin()}
                      >
                        {t("home.signin.useemaillogin")}
                      </button>
                    </div>
                    <RHFInputAddon name="phoneNumber" addon="+60" />
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-sm font-medium text-gray-900">
                        {t("home.signin.email")}
                      </label>
                      <button
                        className="text-sm font-medium text-blue-500"
                        type="button"
                        onClick={() => handleUseLogin()}
                      >
                        {t("home.signin.usephonelogin")}
                      </button>
                    </div>
                    <RHFInput name="email" />
                  </div>
                )}
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="text-sm font-medium text-gray-900">
                      {t("home.signin.password")}
                    </label>
                    <Link
                      to={`/${i18next.language}/forgot-password`}
                      className="text-sm font-medium text-blue-500"
                      reloadDocument
                    >
                      {t("home.signin.forgetpassword")}
                    </Link>
                  </div>
                  <RHFInput name="password" type="password" />
                </div>
                <ButtonLp
                  type="submit"
                  className="w-full mx-auto mt-5"
                  loading={isSubmitting}
                >
                  {t("home.signin.signin")}
                </ButtonLp>
              </div>
            </FormProvider>
            <h1 className="text-md text-center my-10">
              {t("home.signin.newhere")}
              <Link
                to={`/${i18next.language}/sign-up`}
                className="text-blue-500"
              >
                {t("home.signin.createanaccount")}
              </Link>
            </h1>
            <div className="mt-4 text-center">
              <Link to="/" className="font-semibold text-blue-500 text-sm">
                {t("home.signin.backtohome")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ModalAccountLocked open={open} close={() => setOpen(false)} />
    </>
  );
};

export default SignIn;
