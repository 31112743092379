import React, { useState } from "react";
import { Text, Img } from "components";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function HeaderInvestmentMenu({ setOpenMenu, setOpenInvestmentSub }) {
  const navigate = useNavigate();
  const [isExpandSubCrypto, setIsExpandSubCrypto] = useState(false);
  const [isExpandSubShare, setIsExpandSubShare] = useState(false);

  const collapsedMenus = () => {
    setIsExpandSubCrypto(false);
    setIsExpandSubShare(false);
    setOpenInvestmentSub(false);
  };
  return (
    <div>
      <div>
        <div
          className="flex flex-row w-full"
          onClick={() => {
            setIsExpandSubCrypto(!isExpandSubCrypto);
          }}
        >
          <div className="flex w-[30%]"></div>
          <div className="flex flex-col w-[40%] ">
            <Text className="text-[1rem] font-semibold">Crypto</Text>
          </div>

          <div className="flex flex-col w-[30%] items-end">
            {!isExpandSubCrypto ? (
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            ) : (
              <MdKeyboardArrowDown size={30} />
            )}
          </div>
        </div>
        <div className={`${!isExpandSubCrypto ? "hidden" : ""}`}>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/btc");
              }}
            >
              Bitcoin
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/eth");
              }}
            >
              Ethereum
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/dot");
              }}
            >
              Polkadot
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/ht");
              }}
            >
              Houbitoken
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/xrp");
              }}
            >
              Ripple
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/doge");
              }}
            >
              Dogecoin
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/bnb");
              }}
            >
              Binance
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/trx");
              }}
            >
              Tron
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/sol");
              }}
            >
              Solana
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/usdt");
              }}
            >
              USDT
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="flex flex-row w-full"
          onClick={() => {
            setIsExpandSubShare(!isExpandSubShare);
          }}
        >
          <div className="flex w-[30%]"></div>
          <div className="flex flex-col w-[40%] ">
            <Text className="text-[1rem] font-semibold">Share Index</Text>
          </div>

          <div className="flex flex-col w-[30%] items-end">
            {!isExpandSubShare ? (
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            ) : (
              <MdKeyboardArrowDown size={30} />
            )}
          </div>
        </div>
        <div className={`${!isExpandSubShare ? "hidden" : ""}`}>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/SP500");
              }}
            >
              SP500
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/DXY");
              }}
            >
              US Dollar Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/VIX");
              }}
            >
              Volatility Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/DAX");
              }}
            >
              DAX Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/HSI");
              }}
            >
              Hang Seng Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/CAC40");
              }}
            >
              CAC40 Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/NIKKEI");
              }}
            >
              Nikkei Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-[35%]"></div>
            <div
              className="flex w-[35%]"
              onClick={() => {
                setOpenMenu(false);
                collapsedMenus();
                navigate("/dashboard/investment-product/NDX");
              }}
            >
              Nasdaq Index
            </div>
            <div className="flex flex-col w-[30%] items-end">
              <Img className="w-[30px]" src="/images/menu/right-arrow.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
