import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useBankStore from "../../hooks/zustand/bank/useBankStore";
import useLogStore from "../../hooks/zustand/admin-log/useLogStore";
import { Img, Heading, Text } from "components";
// components
import {
  FormProvider,
  RHFInput,
  RHFInputAddon,
  RHFCheckBox,
  RHFSelect,
} from "../../components/RHForm";
import ButtonLp from "../../components/ButtonLp";
import useAuth from "../../hooks/zustand/useAuth";
import { useNavigate } from "react-router-dom";
import { handleRegisterResponse } from "../../lib/api/handleApiResponse";
import { enqueueSnackbar } from "notistack";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import useHomeSlider from "../../hooks/zustand/useHomeSlider";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  // lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .matches(/^\d+$/, "Number only!")
    .min(9, "Minimum 9 digit numbers")
    .max(13, "Maximum 13 digit numbers"),
  email: yup.string().email(),
  password: yup
    .string()
    .min(6, "Minimum 6 digit characters!")
    .max(32, "Maximum 32 digit characters!"),
  referralCode: yup.string(),
  confirm: yup.bool().required("Please confirm this statement"),
});

const bankInformationSchema = yup.object().shape({
  bankHolder: yup.string().required("Account holder is required!"),
  bankAccount: yup.string().required("Bank account number is required!"),
  bankName: yup.string().required("Bank name is required!"),
});

const defaultValues = {
  firstName: "",
  //lastName: '',
  phoneNumber: "",
  email: "",
  password: "",
  referralCode: "",
  confirm: undefined,
  //bankCountry: process.env.REACT_APP_DEFAULT_BANK_COUNTRY
};

const SignUp = () => {
  const auth = useAuth();
  const bank = useBankStore();
  const logStore = useLogStore();
  const homeSliderStore = useHomeSlider();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [bankCountry, setBankCountry] = useState([]);
  const [filteredBankList, setFilterBankList] = useState([]);
  const [bgImg, setBgImg] = useState(process.env.REACT_APP_LOGIN_BG_IMG);

  const closeButtonRef = useRef(null);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const bankInformationMethods = useForm({
    resolver: yupResolver(bankInformationSchema),
  });

  const {
    watch,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const {
    handleSubmit: handleSubmitBank,
    formState: { isSubmitting: isSubmittingBank },
  } = bankInformationMethods;

  const values = watch();

  const initialGetBgImg = async () => {
    const resp = await homeSliderStore.getBgImg();
    if (resp.status === 200) {
      if (resp?.data?.slider?.bg_img ?? "" !== "")
        setBgImg(resp.data.slider.bg_img);
    }
  };

  async function initialize(page) {
    await bank.getData("", page, 500);
    const countries = await bank.getAllBankCountry();
    setBankCountry(countries);
  }

  useEffect(() => {
    const currLanguage =
      params.language ?? process.env.REACT_APP_DEFAULT_LANGUANGE;
    i18next.changeLanguage(currLanguage);
    i18n.language = "en";
    initialize(1);
    //initialGetBgImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setStep(2);
  };

  function onError(error) {
    enqueueSnackbar(`${error}`, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }

  const onSubmitBank = async (data) => {
    const payload = { ...values, ...data };
    console.log("payload", payload);
    const res = await auth.register(payload);
    const memberId = values.phoneNumber;
    await logStore.createData({
      adminId: "",
      memberId: memberId,
      log: `${memberId} login unsuccessful due to suspended`,
      payload: payload,
      action: "signup",
      module: "member",
      table: "users",
    });
    handleRegisterResponse(
      res,
      setStep,
      onError,
      setError,
      navigate,
      i18n.language
    );
  };

  const handleCountryChange = (selectedCountry) => {
    if (bank.data) {
      const newBankList = bank.data.filter(
        (item) => item.bankCountry === selectedCountry._id
      );
      setFilterBankList(newBankList || []);
    }
  };

  return (
    <>
      {/* <div
        className="w-full h-screen relative"
        style={{
          backgroundImage: `url('${bgImg}')`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity:0.5
        }}
      >
        
      </div> */}
      <div className="flex flex-row sm:flex-col-reverse ">
        <div className="w-1/2 flex flex-col  sm:w-full">
          <div className="w-full flex">
            <div className=" bg-primary-primary h-screen md:h-[100%] w-full sm:p-5 ">
              <div className=" m-auto   bg-cover bg-no-repeat ">
                <div className="mt-[3.75rem] flex flex-col gap-[3.00rem]">
                  <Img
                    src="/images/img_illustration.svg"
                    alt="Main Illustration"
                    className="h-[22.13rem]  object-contain "
                  />
                  <div className="mx-[1.75rem] flex flex-col items-center gap-[1.50rem] md:mx-0">
                    <Heading
                      size="heading_h3"
                      as="h2"
                      className="ml-[1.13rem] text-center text-[1.75rem] font-extrabold leading-[2.13rem] text-black-900 md:ml-0 md:text-[1.63rem] sm:text-[1.50rem]"
                    >
                      <>
                        Buy & sell Each Digital
                        <br />
                        Cryptocurrency
                      </>
                    </Heading>
                    <Text
                      as="p"
                      className="text-center text-[0.88rem] font-normal leading-[1.31rem] text-black-900"
                    >
                      <>
                        Easily Buy bitcoin and other cryptocurrencies using
                        <br />
                        wide range of payment option. Discover exclusive
                        <br />
                        digital collection of us.
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2  flex flex-col 
          sm:w-full 
          ">
          <div className="w-full flex justify-end p-5 ">
            <img
              src={process.env.REACT_APP_LOGO_PATH}
              //src='images/Produk-Kewangan-Black-Logo.png'
              alt={`Logo ${process.env.REACT_APP_PROJECT_TITLE}`}
              className="w-24"
            />
          </div>
          <div className="w-full bg-white-a700_99 overflow-hidden px-10 mx-auto">
            <div className="flex flex-col">
              <h1 className="text-xl lg:text-2xl font-semibold mt-3 mb-2">
                {t("home.signup.createanaccount")}
              </h1>
              <h2 className="text-sm lg:text-md  mb-10">
                {step === 1 ? null : t("home.signup.Last step before start")}
              </h2>
            </div>

            {step === 1 ? (
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 lg:gap-4 w-full mx-auto">
                  <RHFInput
                    name="firstName"
                    // label={t("home.signup.nameasperic")}
                    placeholder={t("home.signup.nameasperic")}
                  />
                  {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                    <RHFInput name='firstName' label='First Name' />
                    <RHFInput name='lastName' label='Last Name' />
                  </div> */}
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <RHFInputAddon
                      name="phoneNumber"
                      // label={t("home.signup.phone")}
                      placeholder={t("home.signup.phone")}
                      addon="+60"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <RHFInput
                      name="email"
                      // label={t("home.signup.email")}
                      placeholder={t("home.signup.email")}
                    />
                  </div>
                  <RHFInput
                    name="password"
                    // label={t("home.signup.password")}
                    placeholder={t("home.signup.password")}
                    type="password"
                  />
                  <RHFInput
                    name="referralCode"
                    // label={t("home.signup.referralcode")}
                    placeholder={t("home.signup.referralcode")}
                  />
                  <div className="flex items-center">
                    <RHFCheckBox name="confirm">
                      <button
                        type="button"
                        className="ml-2 text-sm font-medium text-blue-500"
                        onClick={() => setOpen(true)}
                      >
                        {t(
                          "home.signup.Confirming you are not a U.S. citizen or tax resident"
                        )}
                      </button>
                    </RHFCheckBox>
                  </div>
                  <ButtonLp
                    type="submit"
                    className="w-full mx-auto mt-5"
                    loading={isSubmitting}
                  >
                    {t("home.signup.Continue")}
                  </ButtonLp>
                </div>
              </FormProvider>
            ) : null}
            {step === 2 ? (
              <FormProvider
                methods={bankInformationMethods}
                onSubmit={handleSubmitBank(onSubmitBank, (e) => console.log(e))}
                {...methods}
              >
                <div className="flex flex-col gap-4 lg:gap-8 w-full mx-auto">
                  <RHFSelect
                    name="bankCountry"
                    //label={t("home.signup.Bank Country")}
                    listSelect={bankCountry?.map((a) => {
                      return { label: a, _id: a };
                    })}
                    onChange={handleCountryChange}
                  />
                  <RHFSelect
                    name="bankName"
                    //label={t("home.signup.Bank Name")}
                    listSelect={
                      filteredBankList &&
                      filteredBankList?.map((item) => {
                        return { label: item.bankId, _id: item.bankId };
                      })
                    }
                  />
                  <RHFInput
                    name="bankAccount"
                    // label={t("home.signup.Bank Account Number")}
                    placeholder={t("home.signup.Bank Account Number")}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <RHFInput
                    name="bankHolder"
                    // label={t("home.signup.Account Holder Name")}
                    placeholder={t("home.signup.Account Holder Name")}
                  />
                  <ButtonLp
                    type="submit"
                    className="w-full mx-auto mt-5"
                    loading={isSubmittingBank}
                  >
                    {t("home.signup.Submit")}
                  </ButtonLp>
                </div>
              </FormProvider>
            ) : null}
            <div className="pt-5 text-center">
              {t("home.signup.alreadyhaveanaccount")}
              <Link to={`/${i18n.language}/sign-in`} className="text-blue-500">
                {t("home.signup.signinhere")}
              </Link>
            </div>

            <div className="mt-4 text-center sm:pb-10">
              <Link to="/" className="font-semibold text-blue-500 text-sm">
                {t("home.signup.backtohome")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={closeButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg bg-white-a700_99 
                  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="bg-white-a700_99 p-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {i18n.language === "en" ? (
                        <div className="mt-3">
                          <Dialog.Title
                            as="h3"
                            className="font-semibold leading-6"
                          >
                            Confirming you are not a U.S. citizen or tax
                            resident
                          </Dialog.Title>
                          <div className="text-sm mt-10">
                            <p>
                              By giving this statement, I explicitly declare and
                              confirm that:
                            </p>
                            <ul className="list-disc ml-10 my-5">
                              <li>I am not a U.S. citizen or resident</li>
                              <li>
                                I do not directly or indirectly own more than
                                10% of shares/voting rights/interest of the U.S.
                                residents and/or do not control U.S. citizens or
                                residents by other means
                              </li>
                              <li>
                                I am not under the direct or indirect ownership
                                of more than 10% of shares/voting
                                rights/interest and/or under the control of U.S.
                                citizen or resident exercised by other means
                              </li>
                              <li>
                                I am not affiliated with U.S. citizens or
                                residents in terms of Section 1504(a) of FATCA
                              </li>
                              <li>
                                I am aware of my liability for making a false
                                declaration.
                              </li>
                            </ul>
                            <p>
                              For the purposes of this statement, all U.S.
                              dependent countries and territories are equalled
                              to the main territory of the USA. I commit to
                              defend and hold harmless
                              {process.env.REACT_APP_PROJECT_TITLE}, its
                              directors and officers against any claims arising
                              from or related to any breach of my statement
                              hereby.
                            </p>
                          </div>
                        </div>
                      ) : i18n.language === "my" ? (
                        <div className="mt-3">
                          <Dialog.Title
                            as="h3"
                            className="font-semibold leading-6"
                          >
                            Saya mengesahkan bahawa saya bukan warganegara A.S.,
                            pemastautin atau ahli gabungan.
                          </Dialog.Title>
                          <div className="text-sm mt-10">
                            <p>
                              Dengan memberikan kenyataan ini, saya dengan jelas
                              mengisytiharkan dan mengesahkan bahawa:
                            </p>
                            <ul className="list-disc ml-10 my-5">
                              <li>
                                Saya bukan warganegara atau pemastautin A.S
                              </li>
                              <li>
                                Saya tidak memiliki lebih daripada 10% saham/hak
                                mengundi/kepentingan penduduk A.S. secara
                                langsung atau tidak langsung dan/atau tidak
                                mengawal warganegara atau pemastautin A.S.
                                dengan cara lain
                              </li>
                              <li>
                                Saya tidak berada di bawah pemilikan langsung
                                atau tidak langsung lebih daripada 10% saham/hak
                                mengundi/kepentingan dan/atau di bawah kawalan
                                warganegara atau pemastautin A.S. yang
                                dilaksanakan dengan cara lain
                              </li>
                              <li>
                                Saya tidak bergabung dengan warga A.S. atau
                                pemastautin dari segi Seksyen 1504(a) FATCA
                              </li>
                              <li>
                                Saya sedar tentang liabiliti saya kerana membuat
                                pengisytiharan palsu.
                              </li>
                            </ul>
                            <p>
                              Untuk tujuan pernyataan ini, semua negara dan
                              wilayah yang bergantung kepada A.S. disamakan
                              dengan wilayah utama A.S. Saya komited untuk
                              mempertahankan dan tidak memudaratkan{" "}
                              {process.env.REACT_APP_PROJECT_TITLE}, pengarah
                              dan pegawainya terhadap sebarang tuntutan yang
                              timbul daripada atau berkaitan dengan sebarang
                              pelanggaran kenyataan saya dengan ini.
                            </p>
                          </div>
                        </div>
                      ) : i18n.language === "cn" ? (
                        <div className="mt-3">
                          <Dialog.Title
                            as="h3"
                            className="font-semibold leading-6"
                          >
                            我确认我不是美国公民、居民或附属机构。
                          </Dialog.Title>
                          <div className="text-sm mt-10">
                            <p>通过发表此声明，我明确声明并确认：</p>
                            <ul className="list-disc ml-10 my-5">
                              <li>我不是美国公民或居民</li>
                              <li>
                                我没有直接或间接拥有美国居民超过 10%
                                的股份/投票权/权益和/或没有通过其他方式控制美国公民或居民
                              </li>
                              <li>
                                我不直接或间接拥有超过 10%
                                的股份/投票权/权益和/或受到美国公民或居民通过其他方式行使的控制
                              </li>
                              <li>
                                根据 FATCA 第 1504(a) 条，我不属于美国公民或居民
                              </li>
                              <li>我知道我因做出虚假声明而承担的责任。</li>
                            </ul>
                            <p>
                              就本声明而言，所有美国附属国家和领土均等同于美国的主要领土。对于因违反我在此声明而产生或与之相关的任何索赔，我承诺为{" "}
                              {process.env.REACT_APP_PROJECT_TITLE},
                              其董事和管理人员辩护并使其免受损害。
                            </p>
                          </div>
                        </div>
                      ) : i18n.language === "ja" ? (
                        <div className="mt-3">
                          <Dialog.Title
                            as="h3"
                            className="font-semibold leading-6"
                          >
                            I confirm that I am not a U.S. citizen, resident, or
                            affiliate.
                            「私は、米国市民でも居住者でもなく、米国との関連企業でもないことを確認します。」
                          </Dialog.Title>
                          <div className="text-sm mt-10">
                            <p>
                              米国市民または租税居住者でないことの確認
                              以下の表明を行うことにより、私は明示的に宣言し、確認します。
                            </p>
                            <ul className="list-disc ml-10 my-5">
                              <li>私は米国市民または居住者ではありません。</li>
                              <li>
                                私は直接的にも間接的にも、米国居住者の株式/議決権/持分を10%以上所有しておらず、また他の手段により米国市民または居住者を支配していません。
                              </li>
                              <li>
                                私は直接的にも間接的にも、株式/議決権/持分を10%以上所有されておらず、また米国市民または居住者による他の手段による支配下にありません。
                              </li>
                              <li>
                                私は、FATCA第1504条(a)項の意味で米国市民または居住者と関連していません。
                              </li>
                              <li>
                                私は虚偽の申告を行った場合の責任を認識しています。
                              </li>
                            </ul>
                            <p>
                              この表明の目的上、米国すべての属国および領土は、米国本土と同一視されます。私は、本表明の違反に起因または関連するいかなる請求からも、
                              {process.env.REACT_APP_PROJECT_TITLE},
                              とその取締役、役員を防御し、免責することに同意します。
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="bg-gray-50 p-5 flex justify-end">
                    <button
                      type="button"
                      className="rounded-lg bg-white-a700_99 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setOpen(false)}
                      ref={closeButtonRef}
                    >
                      {t("home.signup.Close")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SignUp;
