import { Img, Paragraph, Text } from "components";
import React from "react";
import HomeBestServiceDetail from "./HomeBestServiceDetail";

export default function HomeBestServices() {
  return (
    <div id="service-section" className=" container bg-blue-scfx lg:h-screen flex flex-col items-center justify-center pb-[5rem] ">
      <Text className="text-blue-600 text-[2rem]">Benefit</Text>
      <Text className=" text-[3rem] capitalize font-semibold">
        Our Best Service
      </Text>
      <div className="flex flex-col w-[90%] pt-[2rem] justify-center items-center">
        <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-[2rem] ">
          <HomeBestServiceDetail
            imgSrc="/images/home/safety.svg"
            title="Safety Come first"
            subTitle="Protecting your assets with industry-leading security and cutting-edge encryption, 
                so you can invest with confidence."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/easyDeposit.svg"
            title="Easy Deposit & Withdrawal"
            subTitle="Seamless transactions at your fingertips—quickly deposit or withdraw your funds anytime, with zero hassle and full transparency."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/lowCharge.svg"
            title="Low Charges"
            subTitle="Maximize your returns with competitive fees, designed to help you keep more of your investments working for you."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/gift.svg"
            title="Bonus & Referral"
            subTitle="Earn rewards for investing and sharing—get bonuses for your investments and invite friends to join for even more benefits."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/fastTxn.svg"
            title="Fast Transactions"
            subTitle="Experience lightning-fast deposits, withdrawals, and trades—your transactions completed in seconds, ensuring you never miss an opportunity."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/deepEncryption.svg"
            title="Deep Encryption"
            subTitle="Your data and assets are shielded with advanced encryption technology, providing an extra layer of protection for total peace of mind."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/fastKYC.svg"
            title="Fast KYC"
            subTitle="Get verified in minutes with our streamlined KYC process, ensuring quick access to all platform features without compromising security."
          />
          <HomeBestServiceDetail
            imgSrc="/images/home/support.svg"
            title="24/7 Support"
            subTitle="Round-the-clock assistance from our expert team, ready to help whenever you need it, no matter where you are.."
          />
        </div>
      </div>
    </div>
  );
}
