import {React, useRef} from "react";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";
import HomeTrusted from "../components/HomePage/HomeTrusted";
import HomeInvest from "../components/HomePage/HomeInvest";
import HomeStarted from "../components/HomePage/HomeStarted";
import HomeTestimoni from "../components/HomePage/HomeTestimoni";
import HomeSignUp from "../components/HomePage/HomeSignUp";
import HomeAwards from "../components/HomePage/HomeAwards";
import HomeBuild from "../components/HomePage/HomeBuild";
import HomeCreateProfile from "components/HomePage/HomeCreateProfile";
import HomeBestServices from "components/HomePage/HomeBestServices";
import { Spacing } from "components";
// mock data
//import { mockSliders } from "../mock/mockData";


const Home = () => {
  return (
    <div className="bg-blue-scfx">
    <Layout changeColorWhileScroll={true}>
      <Carousel />

      {/* <HomeTrusted /> */}

      <HomeInvest />
{/* 
      <HomeBuild /> */}
      <HomeCreateProfile />
      <HomeBestServices />

      <HomeTestimoni />

      <HomeAwards />

      <HomeSignUp />
    </Layout>
    </div>
  );
};

export default Home;
