import React from "react";
import { Text, Img } from "components";
import { useNavigate } from "react-router-dom";

export default function HeaderProfileMenu({
  setOpenProfileSub
}) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex flex-row" 
        onClick={()=> {
          navigate("/dashboard/profile")
          setOpenProfileSub(false);
        }}
      >
        <div className="flex flex-col w-[30%]"></div>
        <div className="flex flex-col w-[40%]">Email</div>
        <div className="flex flex-col w-[30%] items-end"><Img className="w-[30px]" src="/images/menu/right-arrow.svg" /></div>
      </div>
      <div className="flex flex-row"
         onClick={()=> {
          navigate("/dashboard/password")
          setOpenProfileSub(false);
        }}
      >
        <div className="flex flex-col w-[30%]"></div>
        <div className="flex flex-col w-[40%]">password</div>
        <div className="flex flex-col w-[30%] items-end"><Img className="w-[30px]" src="/images/menu/right-arrow.svg" /></div>
      </div>
      <div className="flex flex-row"
         onClick={()=> {
          navigate("/dashboard/bank-information")
          setOpenProfileSub(false);
        }}
      >
        <div className="flex flex-col w-[30%]"></div>
        <div className="flex flex-col w-[40%]">Bank Information</div>
        <div className="flex flex-col w-[30%] items-end"><Img className="w-[30px]" src="/images/menu/right-arrow.svg" /></div>
      </div>
    </div>
  );
}
