import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";
import ButtonLp from "../ButtonLp";
import { useTranslation } from "react-i18next";
import { Img, Text } from "components";

const HomeSignUp = () => {
  const { t } = useTranslation();

  return (
    <div className=" bg-neutral-50 flex items-center justify-center">
      <div className="flex flex-col items-center w-[90%] pt-[5rem] pb-[10rem]">
        <div className="flex flex-row h-[12rem] rounded-[16px] w-[70%] bg-[#3671E9]">
          <div className="flex flex-col items-start p-5">
            <Text className="text-white-a700_99 font-semibold text-[2rem] sm:invisible"> Sign Up Now !!! </Text>
            <Text className="text-white-a700_99 text-[1.5rem] sm:text-[0.88rem] sm:mt-[-6rem]">
              Ready to access to the world of investing
            </Text>
          </div>
        </div>
        <div className="flex flex-row justify-end w-[70%] mt-[-8.5rem]">
          <Img src="images/home/bitcoinSign.svg"  alt="sign up"/>
          </div>
          <div className="flex flex-row justify-end w-[50%] mt-[-8.5rem] sm:mt-[-6rem] sm:ml-[-8.5rem]">
            <Link to="/sign-up" >
            <ButtonLp className="uppercase h-[4rem]">
              {t("home.section4.end.button")}
            </ButtonLp>
          </Link>
          </div>
         
      </div>
    </div>
  );
};

export default HomeSignUp;
