import { Route } from 'react-router-dom';
import { WithGuard } from '../middleware/route.guard';
import { lazy, Suspense } from 'react';
import Loading from "components/Loading";

import Dashboard from '../pages/Dashboard/Dashboard';
import ProfitLossHistoryProfile from '../pages/Dashboard/Coin/detail';
import InAppChat from '../pages/Dashboard/InAppChat';

// LAZY LOADING COMPONENT
const Withdrawal = lazy(() => import('../pages/Dashboard/Withdrawal'));
const WithdrawalHistory = lazy(() =>
  import('../pages/Dashboard/WithdrawalHistory')
);
const ProfitLossHistory = lazy(() =>
  import('../pages/Dashboard/ProfitLossHistory')
);
const DepositHistory = lazy(() => import('../pages/Dashboard/DepositHistory'));
const Email = lazy(() => import('../pages/Dashboard/Email'));
const Password = lazy(() => import('../pages/Dashboard/Password'));
const BankInformation = lazy(() =>
  import('../pages/Dashboard/BankInformation')
);
const Profile = lazy(() => import('../pages/Dashboard/Profile'));

const Bitcoin = lazy(() => import('../pages/Dashboard/Coin/Bitcoin'));
const Ethereum = lazy(() => import('../pages/Dashboard/Coin/Ethereum'));
const Polkadot = lazy(() => import('../pages/Dashboard/Coin/Polkadot'));
const Houbi = lazy(() => import('../pages/Dashboard/Coin/Houbi'));
const Ripple = lazy(() => import('../pages/Dashboard/Coin/Ripple'));
const Dogecoin = lazy(() => import('../pages/Dashboard/Coin/Dogecoin'));
const Binance = lazy(() => import('../pages/Dashboard/Coin/Binance'));
const Tron = lazy(() => import('../pages/Dashboard/Coin/Tron'));
const Solana = lazy(() => import('../pages/Dashboard/Coin/Solana'));
const Usdt = lazy(() => import('../pages/Dashboard/Coin/Usdt'));
const Sp500 = lazy(()=> import('../pages/Dashboard/Share/SP500'));
const Us100 = lazy(()=> import('../pages/Dashboard/Share/US100'));
const DowJones = lazy(()=> import('../pages/Dashboard/Share/Dowjones'));
const UsDollarIdx = lazy(() => import('../pages/Dashboard/Share/UsDollarIdx'));
const Volatility = lazy(()=>import('../pages/Dashboard/Share/Volatility'));
const Nasdaq = lazy(()=>import('../pages/Dashboard/Share/Nasdaq'));
const Oil= lazy(() => import('../pages/Dashboard/Commodities/Oil'));
const Gas = lazy(() => import('../pages/Dashboard/Commodities/Gas'));
const Gold = lazy(() => import('../pages/Dashboard/Commodities/Gold'));
const Platinum = lazy(() => import('../pages/Dashboard/Commodities/Platinum'));
const Lithium = lazy(()=> import('../pages/Dashboard/Commodities/Lithium'));
const Nickel = lazy(() => import('../pages/Dashboard/Commodities/Nickel'));
const Cobalt = lazy(() => import('../pages/Dashboard/Commodities/Cobalt'));
const Copper = lazy(() => import('../pages/Dashboard/Commodities/Copper'));
const Cac40 = lazy(() => import('../pages/Dashboard/Share/Cac40'));
const Dax = lazy(() => import('../pages/Dashboard/Share/Dax'));
const Hsi = lazy(()=> import('../pages/Dashboard/Share/Hsi'));
const Nikkei = lazy(()=> import('../pages/Dashboard/Share/Nikkei'));
const Axy = lazy(()=> import('../pages/Dashboard/ForexId/Axy'));
const Bxy = lazy(()=> import('../pages/Dashboard/ForexId/Bxy'));
const Cxy = lazy(() => import('../pages/Dashboard/ForexId/Cxy'));
const Dxy = lazy(() => import('../pages/Dashboard/ForexId/Dxy'));
const Exy = lazy (() => import('../pages/Dashboard/ForexId/Exy'));
const Jxy = lazy(() => import('../pages/Dashboard/ForexId/Jxy'));
const Sxy = lazy(()=> import('../pages/Dashboard/ForexId/Sxy'));
const Zxy = lazy(()=> import('../pages/Dashboard/ForexId/Zxy'));
const InvestmentProduct = lazy(()=> import('../pages/Dashboard/InvestmentProduct'));
const MarketList = lazy(()=> import('../pages/Dashboard/Market'));

// MUST AUTH || USER DASHBOARD
const DashboardGuard = WithGuard(Dashboard);
const WithdrawalGuard = WithGuard(Withdrawal);
const WithdrawalHistoryGuard = WithGuard(WithdrawalHistory);
const ProfitLossHistoryGuard = WithGuard(ProfitLossHistory);
const DepositHistoryGuard = WithGuard(DepositHistory);
const EmailGuard = WithGuard(Email);
const PasswordGuard = WithGuard(Password);
const BankInformationGuard = WithGuard(BankInformation);
const ProfileGuard = WithGuard(Profile);
const BitcoinGuard = WithGuard(Bitcoin);
const EthereumGuard = WithGuard(Ethereum);
const PolkadotGuard = WithGuard(Polkadot);
const HoubiGuard = WithGuard(Houbi);
const RippleGuard = WithGuard(Ripple);
const DogecoinGuard = WithGuard(Dogecoin);
const BinanceGuard = WithGuard(Binance);
const TronGuard = WithGuard(Tron);
const SolanaGuard = WithGuard(Solana);
const UsdtGuard = WithGuard(Usdt);
const Sp500Guard = WithGuard(Sp500);
const Us100Guard = WithGuard(Us100);
const DowJonesGuard = WithGuard(DowJones);
const UsDollarIdxGuard = WithGuard(UsDollarIdx);
const VolatilityGuard = WithGuard(Volatility);
const NasdaqGuard = WithGuard(Nasdaq);
const OilGuard = WithGuard(Oil);
const GasGuard = WithGuard(Gas);
const GoldGuard = WithGuard(Gold);
const PlatinumGuard = WithGuard(Platinum);
const LithiumGuard = WithGuard(Lithium);
const NickelGuard = WithGuard(Nickel);
const CobaltGuard = WithGuard(Cobalt);
const CopperGuard = WithGuard(Copper);
const DetailProfile = WithGuard(ProfitLossHistoryProfile);
const InAppChatGuard = WithGuard(InAppChat);
const Cac40Guard = WithGuard(Cac40);
const DaxGuard = WithGuard(Dax);
const HsiGuard = WithGuard(Hsi);
const NikkeiGuard = WithGuard(Nikkei);
const AxyGuard = WithGuard(Axy);
const BxyGuard = WithGuard(Bxy);
const CxyGuard = WithGuard(Cxy);
const DxyGuard = WithGuard(Dxy);
const ExyGuard = WithGuard(Exy);
const JxyGuard = WithGuard(Jxy);
const SxyGuard = WithGuard(Sxy);
const ZxyGuard = WithGuard(Zxy);
const InvestmentProductGuard = WithGuard(InvestmentProduct);
const MarketListGuard = WithGuard(MarketList);

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route index element={<Suspense fallback={<Loading />}><DashboardGuard /></Suspense>} />,
  <Route path='withdraw' element={<Suspense fallback={<Loading />}><WithdrawalGuard /></Suspense>} />,
  <Route path='withdraw-history' element={<Suspense fallback={<Loading />}><WithdrawalHistoryGuard /></Suspense>} />,
  <Route path='profit-loss-history' element={<ProfitLossHistoryGuard />} />,
  <Route path='deposit-history' element={<Suspense fallback={<Loading />}><DepositHistoryGuard /></Suspense>} />,
  <Route path='email' element={<Suspense fallback={<Loading />}><EmailGuard /></Suspense>} />,
  <Route path='password' element={<Suspense fallback={<Loading />}><PasswordGuard /></Suspense>} />,
  <Route path='bank-information' element={<Suspense fallback={<Loading />}><BankInformationGuard /></Suspense>} />,
  <Route path='profile' element={<Suspense fallback={<Loading />}><ProfileGuard /></Suspense>} />,
  <Route path='bitcoin' element={<Suspense fallback={<Loading />}><Bitcoin /></Suspense>} />,
  <Route path='ethereum' element={<EthereumGuard />} />,
  <Route path='polkadot' element={<PolkadotGuard />} />,
  <Route path='houbitoken' element={<HoubiGuard />} />,
  <Route path='ripple' element={<RippleGuard />} />,
  <Route path='dogecoin' element={<DogecoinGuard />} />,
  <Route path='binance' element={<BinanceGuard />} />,
  <Route path='tron' element={<TronGuard />} />,
  <Route path='solana' element={<SolanaGuard />} />,
  <Route path='usdt' element={<UsdtGuard />} />,
  <Route path='sp500' element={<Sp500Guard />} />,
  <Route path='us100' element={<Us100Guard />} />,
  <Route path='dowjones' element={<DowJonesGuard />} />,
  <Route path='usdollarindex' element={<UsDollarIdxGuard />} />,
  <Route path='volatility' element={<VolatilityGuard />} />,
  <Route path='nasdaq' element={<NasdaqGuard />} />,
  <Route path='cac40' element={<Cac40Guard />} />,
  <Route path='dax' element={<DaxGuard />} />,
  <Route path='hsi' element={<HsiGuard />} />,
  <Route path='nikkei' element={<NikkeiGuard />} />,
  <Route path='forex/axy' element={<AxyGuard />} />,
  <Route path='forex/bxy' element={<BxyGuard />} />,
  <Route path='forex/cxy' element={<CxyGuard />} />,
  <Route path='forex/dxy' element={<DxyGuard />} />,
  <Route path='forex/exy' element={<ExyGuard />} />,
  <Route path='forex/jxy' element={<JxyGuard />} />,
  <Route path='forex/sxy' element={<SxyGuard />} />,
  <Route path='forex/zxy' element={<ZxyGuard />} />,
  <Route path='oil' element={<OilGuard />} />,
  <Route path='gas' element={<GasGuard />} />,
  <Route path='gold' element={<GoldGuard />} />,
  <Route path='platinum' element={<PlatinumGuard />} />,
  <Route path='lithium' element={<LithiumGuard />} />,
  <Route path='nickel' element={<NickelGuard />} />,
  <Route path='cobalt' element={<CobaltGuard />} />,
  <Route path='copper' element={<CopperGuard />} />,
  <Route path='profile-detail/:id' element={<Suspense fallback={<Loading />}><DetailProfile /></Suspense>} />,
  <Route path='in-app-chat' element={<InAppChatGuard />} />,
  <Route path='investment-product/:coin' element={<Suspense fallback={<Loading />}><InvestmentProductGuard /></Suspense>} />,
  <Route path='investment-product' element={<Suspense fallback={<Loading />}><InvestmentProductGuard /></Suspense>} />,
  <Route path='market-list' element={<Suspense fallback={<Loading />}><MarketListGuard /> </Suspense>}/>
];
