import React, { useCallback, useState, useEffect, useRef } from "react";
import SectionTitle from "../SectionTitle";
import { useTranslation } from "react-i18next";
import useEmblaCarousel from "embla-carousel-react";
import AwardsViewWidget from "../../pages/Dashboard/Coin/AwardsView";
import { Img, Text } from "components";

const HomeAwards = () => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: false,
  });

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const BTCReff = useRef(null);

  return (
    <div id="award-section" className="h-screen mx-auto max-w-screen-xl flex flex-col items-center justify-center pt-[10rem] ">
      <div className="flex flex-col justify-center items-center py-[3.5rem] w-full">
        <div className=" flex flex-row  w-[80%]   justify-center border-b-2 border-gray-600"></div>
        <div className="mt-[-1rem] z-10 bg-black-900 h-[2rem] w-32 text-white-a700_99">
          <SectionTitle className="">
            {t("home.section5.title")}
          </SectionTitle>
        </div>
      </div>
      <div className="flex flex-row justify-center">
      <div className="grid grid-cols-5 sm:grid-cols-1  w-[90%]">
     
     <div className="flex flex-row gap-2  items-center">
       <Img
         className="w-[50px]"
         src="images/awards/Best Forex Copy Trading Platform 2023.png"
         alt=" Best Forex Copy Trading Platform 2023"
       />
       <Text className="text-xs  text-wrap">
         Best Forex Copy Trading Platform 2023
       </Text>
     </div>
     <div className="flex flex-row gap-2  items-center ">
       <Img
         className="w-[50px]"
         src="images/awards/Best Trading Conditions Asia 2021.png"
         alt="Best Trading Conditions Asia 2021"
       />
       <Text className="text-xs text-wrap">
         Best Trading Conditions Asia 2021
       </Text>
     </div>
     <div className="flex flex-row gap-2  items-center ">
       <Img
         className="w-[50px]"
         src="images/awards/Best Forex Broker India 2023.png"
         alt="Best Forex Broker India 2023"
       />
       <Text className="text-xs text-wrap">
         Best Forex Broker India 2023
       </Text>
     </div>
     <div className="flex flex-row gap-2  items-center ">
       <Img
         className="w-[50px]"
         src="images/awards/Best ECN Broker 2023.png"
         alt="Best ECN Broker 2023"
       />
       <Text className="text-xs text-wrap">
         Best ECN Broker 2023
       </Text>
     </div>
     <div className="flex flex-row gap-2 items-center ">
       <Img
         className="w-[50px]"
         src="images/awards/Best Trading Platform 2022.png"
         alt="Best Trading Platform 2022"
       />
       <Text className="text-xs text-wrap">
         Best Trading Platform 2022
       </Text>
     </div>

 </div>
      </div>
     
      <div className="flex flex-auto justify-center py-[4rem]">
          <div className="">
            <a
              href="/Awards"
              className="underline font-semibold hover:text-blue-500 cursor-pointer"
            >
              View More Rewards
            </a>
          </div>
        </div>
      

    </div>
  );
};

export default HomeAwards;
